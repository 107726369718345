var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "mission-container__transition", mode: "out-in" } },
    [
      _vm.isPollResult
        ? _c("PollResultContainer", { attrs: { mission: _vm.mission } })
        : _vm.isRatingPollResult
        ? _c(
            "Mission",
            {
              staticClass: "mission-container--no-select",
              attrs: { mission: _vm.mission },
            },
            [_c("RatingPollResult", { attrs: { mission: _vm.mission } })],
            1
          )
        : _vm.isResult
        ? _c("ResultContainer", { attrs: { mission: _vm.mission } })
        : _c(
            "Mission",
            {
              key: _vm.transitionKey,
              staticClass: "mission-container--no-select",
              attrs: { mission: _vm.mission },
            },
            [
              _vm._t("default"),
              parseInt(_vm.points && _vm.mission.points)
                ? _c(
                    "RtbPill",
                    {
                      staticClass: "mission-container__points",
                      class: {
                        "mission-container__points--enlarged": _vm.enlargedUI,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.mission.points) + " PTS ")]
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }