<template>
  <transition name="mission-container__transition" mode="out-in">
    <PollResultContainer :mission="mission" v-if="isPollResult" />
    <Mission
      :mission="mission"
      v-else-if="isRatingPollResult"
      class="mission-container--no-select"
    >
      <RatingPollResult :mission="mission" />
    </Mission>
    <ResultContainer :mission="mission" v-else-if="isResult" />
    <Mission
      v-else
      class="mission-container--no-select"
      :mission="mission"
      :key="transitionKey"
    >
      <slot />
      <RtbPill
        v-if="parseInt(points && mission.points)"
        class="mission-container__points"
        :class="{
          'mission-container__points--enlarged': enlargedUI
        }"
      >
        {{ mission.points }} PTS
      </RtbPill>
    </Mission>
  </transition>
</template>

<script>
import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"
import { RtbPill } from "@/components/ui"
import useEnlargedUI from "@/use/useEnlargedUI"

export default {
  name: "MissionContainer",
  components: {
    RtbPill,
    Mission: () => import("@/components/GroupTeams/Common/Games/Mission.vue"),
    ResultContainer: () =>
      import("@/components/GroupTeams/Common/Games/ResultContainer"),
    PollResultContainer: () =>
      import("@/components/GroupTeams/Common/Games/PollResultContainer"),
    RatingPollResult: () =>
      import("@/components/GroupTeams/Common/Games/RatingPoll/RatingPollResult")
  },
  setup() {
    const { enlargedUI } = useEnlargedUI()
    return { enlargedUI }
  },
  computed: {
    transitionKey() {
      return this.mission?.activityId == null
        ? this.mission?.id
        : this.mission?.activityId
    },
    isResult() {
      return this.mode === Mode.Results
    },
    isPollResult() {
      return this.isResult && this.mission?.behavior === MissionType.Poll
    },
    isRatingPollResult() {
      return this.isResult && this.mission?.behavior === MissionType.RatingPoll
    },
    transitionKey() {
      return this.mission?.activityId || this.mission?.id
    }
  },
  props: {
    mode: String,
    mission: Object,
    points: Boolean
  }
}
</script>
<style lang="scss">
.mission-container {
  &__transition-enter-active,
  &__transition-leave-active {
    transition: opacity ease 0.5s;
    opacity: 1;
  }
  &__transition-enter {
    opacity: 0;
  }
  &__transition-leave-to {
    opacity: 0;
  }
  &--no-select {
    user-select: none;
  }
  &__points {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);

    &--enlarged {
      transform: scale(1.5) translateX(-50%) translateY(30%);
    }
  }
}
</style>
